import { useQuery } from "@tanstack/react-query";
import { baseURL } from "../constants";
import { IPublicClientApplication, SilentRequest } from "@azure/msal-browser";

export const fetchAccessTokenWithMsal = async (
    instance: IPublicClientApplication,
    request: SilentRequest,
): Promise<string> => {
    try {
        // Ensure MSAL is initialized and instance is ready
        if (!instance || !instance.getAllAccounts) {
            console.error("MSAL instance is not initialized.");
            return "";
        }

        const accounts = instance.getAllAccounts();

        // If no accounts, redirect to login
        if (accounts.length === 0) {
            console.warn("No accounts found. Redirecting to login...");
            await instance.loginRedirect(request);
            return "";
        }

        const response = await instance.acquireTokenSilent({
            ...request,
            account: accounts[0], // Use the first account
        });

        return response.accessToken;
    } catch (error) {
        console.error("Error during token acquisition:", error);
        sessionStorage.clear();
        return "";
    }
};
