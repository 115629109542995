import { useContext, useEffect, useState } from "react";

import {
    TableScroller,
    Table,
    TableHead,
    TableHeadRow,
    TableHeadCell,
    TableBody,
    TableBodyRow,
    TableBodyCell,
    H5,
    Input,
    GhostIconButton,
    DropDownFlex,
    TableFoot,
    TableFootRow,
    TableFootCell,
    SimpleButton,
    Spinner,
    useToasts,
    Tooltip,
} from "@tag/tag-components-react-v4";
import {
    Circle3QuarterFilled,
    CircleTickFilled,
    DeleteFilled,
    HourglassFullFilled,
    SaveDiskFilled,
} from "@tag/tag-icons";

import dayjs from "dayjs";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { TOAST_CONTAINER_ID } from "../../constants";
import {
    addActivity,
    areButtonsDisabled,
    calculateColumnTotals,
    calculateRowTotal,
    calculateTotalSum,
    fillWeek,
    getFilteredColumns,
    handleActivityChange,
    handleDeleteRow,
    handleInputChange,
    isAllWeekApproved,
    isInputDisabled,
    isMainActivityFilled,
    ITimesheet,
    mapDataToActivities,
    shouldDisableDropdown,
} from "../../utils/rdTimesheetUtils";
import { useGetActivities } from "../../api/useGetActivites";
import { getTimesheetColumns } from "../../utils/TimesheetUtils";
import { useAddUpdateRDTimesheet } from "../../api/RD/useAddOrUpdateRDTimesheet";
import { useGetRDTimesheet } from "../../api/RD/useGetRDTimesheet";
import { ErrorCard } from "../commons/ErrorCard";
import { getHolidayIcons } from "../../utils/getIconsWithAccents";
import { ActivityModel } from "../../models/ActivityModel";
import {
    isAfterLast3rdWorkingDay,
    isCurrentMonth,
    isFutureWeek,
    isPastWeek,
} from "../../utils/dateUtils";
import {
    cellStyle,
    divStyle,
    h5Style,
    inputStyleDays,
    basicInputStyle,
    tableBodyRowStyle,
    tableBodyStyle,
    tableHeadStyle,
    columnTotalsCellStyle,
    totalSummCellStyle,
} from "./RDTable.styles";
import { on } from "events";

export type RDTableProps = {
    startDate: Date;
    endDate: Date;
    enabledPercentage?: boolean;
    setEnableFetch?: (value: boolean) => void;
    onSave?: () => void; 
};

export const RDTable = (props: RDTableProps) => {
    const { startDate, endDate, setEnableFetch, onSave } = props;
    const { profile, accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const [timesheet, setTimesheet] = useState<ITimesheet>({ activities: [], holidays: [] });
    const [isWeekFilled, setIsWeekFilled] = useState(false); // Track if "Fill Week" is clicked
    const columns = getTimesheetColumns(startDate, endDate);

    const { data: activitiesData, isLoading: isActivitiesLoading } = useGetActivities();

    const {
        data: timesheetData,
        isLoading: isTimesheetLoading,
        error: timesheetError,
    } = useGetRDTimesheet(startDate, endDate);

    const { mutateAsync: saveTimesheet, isPending: isAddUpdatePending } = useAddUpdateRDTimesheet();

    useEffect(() => {
        if (timesheetData && profile) {
            const initialActivities = mapDataToActivities(
                timesheetData,
                profile,
                setIsWeekFilled,
                columns,
            ); // Map data on load
            setTimesheet(initialActivities);
        }
        setIsWeekFilled(false);
    }, [profile, startDate, endDate, timesheetData]);

    useEffect(() => {
        const filteredColumns = getFilteredColumns(
            columns,
            profile,
            dayjs(startDate),
            dayjs(endDate),
        );
        // const shouldBePastFilled = isPastWeek(startDate, endDate) &&
        //     (isAllWeekApproved(timesheet) || isMainActivityFilled(timesheet, filteredColumns, columns, profile));
        // const shouldBeFutureFilled = isFutureWeek(startDate, endDate) && isMainActivityFilled(timesheet, filteredColumns, columns, profile);
        // if (isFutureWeek(startDate, endDate) || isMainActivityFilled(timesheet, filteredColumns, columns, profile)) {
        //     console.log("future", shouldBeFutureFilled);
        //     setIsWeekFilled(shouldBeFutureFilled);
        // } else if (isPastWeek(startDate, endDate) && isWeekFilled !== shouldBePastFilled) {
        //     console.log("isWeekFilled", shouldBePastFilled);
        //     setIsWeekFilled(shouldBePastFilled);
        // }
        const isMainActivityFilledVar = isMainActivityFilled(
            timesheet,
            filteredColumns,
            columns,
            profile,
            // true,
        );
        if (isPastWeek(startDate, endDate)) {
            if (isMainActivityFilledVar) setIsWeekFilled(true);
            if (isAllWeekApproved(timesheet)) setIsWeekFilled(true);
        }
        if (isFutureWeek(startDate, endDate)) {
            if (isMainActivityFilledVar) setIsWeekFilled(true);
            if (isAllWeekApproved(timesheet)) setIsWeekFilled(true);
        }
        if (calculateTotalSum(columnTotals) === 5 * (profile?.workingHours ?? 8))
            setIsWeekFilled(true);
        else setIsWeekFilled(false);
    }, [startDate, endDate]);

    const columnTotals = profile ? calculateColumnTotals(timesheet, columns, profile) : [];

    const handleSave = () => {
        const payload = timesheet.activities
            .filter((activity) => activity.selectedActivityId !== undefined)
            .map((activity) => {
                // Create an array to store the complete list of days for the activity
                const days = [];

                // Loop through all days between startDate and endDate
                const start = new Date(startDate.setHours(4));
                const end = new Date(endDate.setHours(4));
                // Set end date to be inclusive
                while (start <= end) {
                    // Find the activity's corresponding day or create an empty one with numberOfHours set to 0
                    const entry = activity.days.find((day, index) => {
                        const checkDate = new Date(startDate);
                        // Find the matching date by comparing the ISO string of both dates
                        checkDate.setDate(startDate.getDate() + index);
                        return (
                            dayjs(start).format("YYYY-MM-DD") ===
                            dayjs(checkDate).format("YYYY-MM-DD")
                        );
                    });

                    // Only push the day if the number of hours is greater than 0
                    if (entry !== undefined && entry.numberOfHours > 0) {
                        if (isCurrentMonth(dayjs(start).format("Do MMM YYYY"))) {
                            days.push({
                                date: dayjs(start).add(2, "hours").toISOString(), // Use dayjs to format the date
                                numberOfHours: entry.numberOfHours ?? 0, // Set hours if available, otherwise 0
                            });
                        }
                    }

                    // Move to the next day
                    start.setDate(start.getDate() + 1);
                }

                return {
                    activityTypeId: activity.selectedActivityId as number,
                    days,
                };
            })
            .filter((activity) => activity.days.length > 0); // Filter out activities with no days to save

        saveTimesheet({ rdTimesheet: { activities: payload }, token: accessToken });
        setEnableFetch && setEnableFetch(true); // Set enableFetch to true after saving
        onSave && onSave(); // Call the onSave function if provided
    };

    const holidayDays = new Set(
        timesheet.holidays.flatMap((holiday) =>
            holiday.days.map((day) => dayjs(day).format("Do MMM YYYY")),
        ),
    );

    if (isActivitiesLoading || isTimesheetLoading) return <Spinner>Loading...</Spinner>;
    else if (timesheetError) return <ErrorCard error={timesheetError} />;
    else
        return (
            <div style={divStyle}>
                {/* Table Headers */}
                <TableScroller height="75vh" className="activity-table-body">
                    <Table>
                        <TableHead style={tableHeadStyle}>
                            <TableHeadRow>
                                <TableHeadCell style={cellStyle}>Activity</TableHeadCell>
                                {columns.map((column, index) => (
                                    <TableHeadCell key={`${column}-${index}`} style={cellStyle}>
                                        {column}
                                    </TableHeadCell>
                                ))}
                                <TableHeadCell style={cellStyle}>Total</TableHeadCell>
                                <TableHeadCell style={cellStyle}>Actions</TableHeadCell>
                            </TableHeadRow>
                        </TableHead>

                        <TableBody style={tableBodyStyle}>
                            {timesheet.holidays.map((row, rowIndex) => (
                                <TableBodyRow key={`holiday-${row.description}-${rowIndex}`}>
                                    <TableBodyCell style={cellStyle}>
                                        <H5 style={h5Style}>
                                            {getHolidayIcons(row.description)}{" "}
                                            {row.description ?? ""}
                                        </H5>
                                    </TableBodyCell>
                                    {columns.map((column, columnIndex) => {
                                        // Match the current column date with row.days
                                        const day = row.days.find(
                                            (day) =>
                                                dayjs(day).format("YYYY-MM-DD") ===
                                                dayjs(startDate)
                                                    .add(columnIndex, "day")
                                                    .format("YYYY-MM-DD"),
                                        );

                                        return (
                                            <TableBodyCell
                                                key={`${rowIndex}-${columnIndex}`}
                                                style={cellStyle}
                                            >
                                                <Input
                                                    disabled
                                                    value={day ? profile?.workingHours : 0} // Display hours if day matches; otherwise, leave empty
                                                    style={basicInputStyle}
                                                />
                                            </TableBodyCell>
                                        );
                                    })}

                                    <TableBodyCell style={cellStyle}>
                                        <Input
                                            disabled
                                            value={profile ? calculateRowTotal(row, profile) : 0}
                                            style={basicInputStyle}
                                        />
                                    </TableBodyCell>
                                    <TableBodyCell style={cellStyle}>
                                        <GhostIconButton
                                            disabled
                                            icon={<DeleteFilled />}
                                            accent="teal"
                                        />
                                    </TableBodyCell>
                                </TableBodyRow>
                            ))}
                            {timesheet.activities.map((row, rowIndex) => (
                                <TableBodyRow
                                    key={`${row.type}-${rowIndex}`}
                                    style={tableBodyRowStyle(activitiesData, row)}
                                >
                                    <TableBodyCell style={cellStyle}>
                                        <DropDownFlex
                                            textField={"name"}
                                            valueField={"value"}
                                            value={row.selectedActivityId ?? null}
                                            data={
                                                activitiesData
                                                    ? activitiesData.map(
                                                          (activity: ActivityModel) => ({
                                                              value: activity.id,
                                                              name: activity.rd
                                                                  ? activity.descriptionEN
                                                                  : `${
                                                                        activity.descriptionEN +
                                                                        " *"
                                                                    }`,
                                                          }),
                                                      )
                                                    : []
                                            }
                                            style={{ width: "10em" }}
                                            readOnly={
                                                isAfterLast3rdWorkingDay() ||
                                                 shouldDisableDropdown(row, startDate, endDate, columns, isAllWeekApproved(timesheet))
                                            }
                                            onChange={(e) =>
                                                handleActivityChange(
                                                    e,
                                                    rowIndex,
                                                    timesheet,
                                                    setTimesheet,
                                                    toast,
                                                )
                                            }
                                        />
                                    </TableBodyCell>
                                    {row.days.map((day, dayIndex) => {
                                        return (
                                            <TableBodyCell
                                                key={`${rowIndex}-${dayIndex}`}
                                                style={{ ...cellStyle }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        margin: `${
                                                            day.isActivityApprovedForDay ||
                                                            day.numberOfHours > 0
                                                                ? "0 0 0 23px"
                                                                : "0 0 0 0"
                                                        }`,
                                                    }}
                                                >
                                                    <Input
                                                        inputMode="numeric"
                                                        type="number"
                                                        value={day.numberOfHours}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                rowIndex,
                                                                dayIndex,
                                                                e.target.value,
                                                                timesheet,
                                                                profile,
                                                                setTimesheet,
                                                            )
                                                        }
                                                        disabled={isInputDisabled(
                                                            row,
                                                            holidayDays,
                                                            columns[dayIndex],
                                                            day.isActivityApprovedForDay,
                                                            isAllWeekApproved(timesheet),
                                                            profile?.startDate,
                                                            profile?.leaveDate,
                                                        )}
                                                        style={inputStyleDays(
                                                            isInputDisabled(
                                                                row,
                                                                holidayDays,
                                                                columns[dayIndex],
                                                                day.isActivityApprovedForDay,
                                                                isAllWeekApproved(timesheet),
                                                                profile?.startDate,
                                                                profile?.leaveDate,
                                                            ),
                                                        )}
                                                    />
                                                    {day.numberOfHours > 0 &&
                                                        day.isActivityApprovedForDay && (
                                                            <Tooltip
                                                                tooltipFor={
                                                                    <CircleTickFilled
                                                                        accent="green"
                                                                        size="small"
                                                                        style={{ width: "24px" }}
                                                                    />
                                                                }
                                                            >
                                                                Approved
                                                            </Tooltip>
                                                        )}
                                                    {day.numberOfHours > 0 &&
                                                        !day.isActivityApprovedForDay && (
                                                            <Tooltip
                                                                tooltipFor={
                                                                    <Circle3QuarterFilled
                                                                        size="small"
                                                                        style={{ width: "20px" }}
                                                                    />
                                                                }
                                                            >
                                                                Approval Pending
                                                            </Tooltip>
                                                        )}
                                                </div>
                                            </TableBodyCell>
                                        );
                                    })}
                                    <TableBodyCell style={cellStyle}>
                                        <Input
                                            disabled
                                            value={profile ? calculateRowTotal(row, profile) : 0}
                                            style={basicInputStyle}
                                        />
                                    </TableBodyCell>
                                    <TableBodyCell style={cellStyle}>
                                        <GhostIconButton
                                            disabled={
                                                row.type === "holiday" ||
                                                row.type === "main" ||
                                                !isCurrentMonth(
                                                    dayjs(endDate).format("Do MMM YYYY"),
                                                ) ||
                                                isAfterLast3rdWorkingDay() ||
                                                isAllWeekApproved(timesheet)
                                            } // Disable delete for holiday rows
                                            icon={<DeleteFilled />}
                                            accent="teal"
                                            onClick={() =>
                                                handleDeleteRow(
                                                    rowIndex,
                                                    setTimesheet,
                                                    columns,
                                                    profile,
                                                )
                                            }
                                        />
                                    </TableBodyCell>
                                </TableBodyRow>
                            ))}
                        </TableBody>

                        {/* Table Footer */}
                    </Table>
                </TableScroller>

                <Table>
                    <TableFoot style={{ position: "sticky", bottom: "0", zIndex: 1 }}>
                        <TableFootRow>
                            <TableFootCell style={cellStyle}>
                                <H5>Total</H5>
                            </TableFootCell>
                            {columnTotals.map((total, index) => (
                                <TableFootCell key={`${total}-footer=${index}`} style={cellStyle}>
                                    <H5 style={columnTotalsCellStyle(profile, total)}>{total}</H5>
                                </TableFootCell>
                            ))}
                            <TableFootCell style={cellStyle}>
                                <H5 style={totalSummCellStyle(profile, columnTotals)}>
                                    {calculateTotalSum(columnTotals)}
                                </H5>
                            </TableFootCell>
                            <TableFootCell style={cellStyle}></TableFootCell>
                        </TableFootRow>
                    </TableFoot>
                </Table>
                {/* Buttons */}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <SimpleButton
                            accent="teal"
                            disabled={
                                areButtonsDisabled(
                                    timesheet,
                                    profile,
                                    columns,
                                    startDate,
                                    endDate,
                                    isWeekFilled,
                                ).fillWeek
                            }
                            onClick={() =>
                                fillWeek(profile, timesheet, columns, setTimesheet, setIsWeekFilled)
                            }
                        >
                            Fill Week
                        </SimpleButton>
                        <SimpleButton
                            accent="teal"
                            disabled={
                                areButtonsDisabled(
                                    timesheet,
                                    profile,
                                    columns,
                                    startDate,
                                    endDate,
                                    isWeekFilled,
                                ).addActivity
                            }
                            onClick={() => addActivity(setTimesheet, timesheet)}
                        >
                            Add Activity
                        </SimpleButton>
                    </div>
                    <SimpleButton
                        accent="green"
                        startIcon={
                            isAddUpdatePending ? <HourglassFullFilled /> : <SaveDiskFilled />
                        }
                        onClick={handleSave}
                        // disabled={
                        //     isAddUpdatePending ||
                        //     calculateTotalSum(columnTotals) > 5 * (profile?.workingHours ?? 0) ||
                        //     areButtonsDisabled(
                        //         timesheet,
                        //         profile,
                        //         columns,
                        //         startDate,
                        //         endDate,
                        //         isWeekFilled,
                        //     ).save
                        // }
                    >
                        Save
                    </SimpleButton>
                </div>
            </div>
        );
};
