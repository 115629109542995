import { getHolidayName } from "./holidayUtils";
import { ProfileModel } from "../models/ProfileModel";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { isAfterLast3rdWorkingDay, isCurrentMonth, isFutureMonth, isPastMonth } from "./dateUtils";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);

export type Activity = {
    selectedActivityId?: number;
    type: string;
    days: { numberOfHours: number; isActivityApprovedForDay: boolean }[];
    activityTypeId?: number;
    isRD?: boolean;
};

export type IHoliday = {
    holidayTypeId: number;
    days: Date[];
    description: string;
    type?: string;
};
export type ITimesheet = {
    activities: Activity[];
    holidays: IHoliday[];
};

export const mapDataToActivities = (
    data: ITimesheet,
    profile: ProfileModel,
    setIsWeekFilled: (e: boolean) => void,
    columns: any[],
): ITimesheet => {
    const activitiesMapped: Activity[] = [];

    const startDate = dayjs(profile.startDate);
    const leaveDate = dayjs(profile.leaveDate);
    const today = dayjs();
    const startOfWeek = today.startOf("week").add(1, "day"); // Monday
    const endOfWeek = startOfWeek.add(4, "day"); // Friday

    // Check if profile.startDate or profile.leaveDate is within the current week
    const isRelevantWeek =
        startDate.isBetween(startOfWeek, endOfWeek, "day", "[]") ||
        leaveDate.isBetween(startOfWeek, endOfWeek, "day", "[]");

    if (!isRelevantWeek) {
        setIsWeekFilled(false);
        return { activities: [], holidays: [] };
    }

    // Helper function to map days to weekdays (Monday to Friday)
    const mapDaysToWeekdays = (
        days: { weekDay: string; numberOfHours: number; isActivityApprovedForDay: boolean }[],
    ) => {
        const weekdays: { numberOfHours: number; isActivityApprovedForDay: boolean }[] = Array.from(
            { length: 5 },
            () => ({
                numberOfHours: 0,
                isActivityApprovedForDay: false,
            }),
        );

        days.forEach((day) => {
            const weekDay = dayjs(day.weekDay, "Do MMM YYYY").toDate();
            let weekdayIndex = weekDay.getDay() - 1; // Shift Monday to 0

            if (weekdayIndex === -1) weekdayIndex = 6; // If Sunday, map to Saturday

            if (weekdayIndex >= 0 && weekdayIndex <= 4) {
                weekdays[weekdayIndex] = {
                    numberOfHours: day.numberOfHours ?? 0,
                    isActivityApprovedForDay: day.isActivityApprovedForDay,
                };
            }
        });

        return weekdays;
    };

    // Map activities within the valid date range
    if (data.activities && Array.isArray(data.activities)) {
        data.activities.forEach((activity) => {
            const activityType =
                activity.activityTypeId === profile.activityTypeId ? "main" : "activity";

            const mappedDays = mapDaysToWeekdays(
                activity.days
                    .filter((d: any) => {
                        const activityDate = dayjs(d.date);
                        return activityDate.isBetween(startDate, leaveDate, "day", "[]");
                    })
                    .map((d: any) => {
                        const dayIndex = columns.indexOf(dayjs(d.date).format("Do MMM YYYY"));
                        return {
                            weekDay: columns[dayIndex], // Assuming columns contain the weekdays
                            numberOfHours: d.numberOfHours,
                            isActivityApprovedForDay: d.isActivityApprovedForDay,
                        };
                    }),
            );

            if (mappedDays.some((d) => d.numberOfHours > 0)) {
                activitiesMapped.push({
                    selectedActivityId: activity.activityTypeId,
                    type: activityType,
                    days: mappedDays, // Store the updated structure
                });
            }
        });

        //     setIsWeekFilled(activitiesMapped.length > 0);
        // } else {
        //     setIsWeekFilled(false);
    }

    // Sort activities to place "main" type first
    activitiesMapped.sort((a, b) => (a.type === "main" ? -1 : b.type === "main" ? 1 : 0));

    // Map holidays
    const holidaysMapped: IHoliday[] = [];
    if (data.holidays && Array.isArray(data.holidays)) {
        data.holidays.forEach((holiday) => {
            holidaysMapped.push({
                type: "holiday",
                holidayTypeId: holiday.holidayTypeId,
                days: holiday.days.map((day) => new Date(day)), // Convert strings to Date objects
                description: getHolidayName(holiday.holidayTypeId), // Get holiday description
            });
        });
    }

    return {
        activities: activitiesMapped,
        holidays: holidaysMapped,
    };
};

export const calculateRowTotal = (
    row: {
        days?: { numberOfHours: number; isActivityApprovedForDay: boolean }[] | any[];
        type?: string;
    },
    profile: ProfileModel,
) => {
    if (row.type === "holiday") {
        // For holiday rows, calculate the total based on the number of days in the row
        return Array.isArray(row.days) ? row.days.length * (profile.workingHours ?? 0) : 0;
    }
    if (row.type === "main") {
        // For the main activity or activity rows, return the sum of the numberOfHours
        return (row.days ?? []).reduce((sum, day) => sum + (day.numberOfHours || 0), 0);
    }
    // For all other rows, sum up the days normally
    return (row.days ?? []).reduce((sum, val) => sum + (val.numberOfHours || 0), 0);
};

export const calculateColumnTotals = (
    timesheet: ITimesheet,
    columns: any[],
    profile: ProfileModel,
) => {
    const totals = Array.from({ length: columns.length }, () => 0);

    // Calculate hours for holidays
    if (timesheet.holidays.length > 0 && profile?.workingHours) {
        timesheet.holidays.forEach((holiday) => {
            holiday.days.forEach((holidayDate) => {
                const holidayLocalDate = dayjs.utc(holidayDate).local().format("Do MMM YYYY");
                const columnIndex = columns.findIndex((column) => column === holidayLocalDate);

                if (columnIndex !== -1) {
                    totals[columnIndex] += profile.workingHours; // Add holiday hours to the respective day
                }
            });
        });
    }

    // Calculate hours for activities
    timesheet.activities.forEach((row) => {
        if (!Array.isArray(row.days)) {
            console.warn("row.days is not an array:", row.days);
            return; // Skip this row if days is not an array
        }

        row.days.forEach(
            (
                value: { numberOfHours: number; isActivityApprovedForDay: boolean },
                index: number,
            ) => {
                totals[index] += value.numberOfHours || 0;
            },
        );
    });

    return totals;
};

export const handleActivityChange = (
    e: any,
    rowIndex: number,
    timesheet: ITimesheet,
    setTimesheet: (e: any) => void,
    toast: any,
) => {
    const selectedValue = e.target.value;
    const isAlreadySelected = timesheet.activities.some(
        (activity: Activity, index: number) =>
            activity.selectedActivityId === selectedValue && index !== rowIndex,
    );

    if (isAlreadySelected) {
        toast("This activity is already selected. Please choose another one.", {
            toastType: "warning",
        });
        return;
    }

    const updatedActivities = [...timesheet.activities];
    if (updatedActivities[rowIndex]) {
        updatedActivities[rowIndex].selectedActivityId = selectedValue;
    }
    setTimesheet({ activities: updatedActivities, holidays: timesheet.holidays });
};

export const handleInputChange = (
    rowIndex: number,
    dayIndex: number,
    value: string,
    timesheet: ITimesheet,
    profile: ProfileModel | null,
    setTimesheet: (e: any) => void,
) => {
    const updatedActivities = [...timesheet.activities];
    const inputValue = Math.max(0, parseInt(value) || 0); // Ensure min value is 0

    const currentDayTotal = updatedActivities.reduce((sum, row, index) => {
        if (row.type !== "main" || index === rowIndex)
            return sum + row.days[dayIndex].numberOfHours;
        return sum;
    }, 0);

    const maxAllowed =
        (profile?.workingHours ?? 8) -
        currentDayTotal +
        updatedActivities[rowIndex].days[dayIndex].numberOfHours;
    const finalValue = Math.min(inputValue, maxAllowed);

    // Adjust Main Activity
    const mainActivityIndex = updatedActivities.findIndex((row) => row.type === "main");
    if (mainActivityIndex !== -1 && rowIndex !== mainActivityIndex) {
        const difference = finalValue - updatedActivities[rowIndex].days[dayIndex].numberOfHours;
        updatedActivities[mainActivityIndex].days[dayIndex].numberOfHours = Math.max(
            updatedActivities[mainActivityIndex].days[dayIndex].numberOfHours - difference,
            0,
        );
    }

    updatedActivities[rowIndex].days[dayIndex].numberOfHours = finalValue;
    setTimesheet({ activities: updatedActivities, holidays: timesheet.holidays });
};

export const handleDeleteRow = (
    rowIndex: number,
    setTimesheet: (e: any) => void,
    columns: string[], // Pass columns containing dates for mapping dayIndex
    profile: ProfileModel | null,
) => {
    setTimesheet((prevTimesheet: ITimesheet) => {
        // Filter out the row to delete
        const updatedActivities = prevTimesheet.activities.filter((_, index) => index !== rowIndex);

        // Check if there are any activities left and if the first is the "main" activity
        if (updatedActivities.length > 0 && updatedActivities[0].type === "main") {
            // const newTotals = calculateNewTotals(updatedActivities);

            // Extract holiday days into a Set for quick lookup
            const holidayDays = new Set(
                prevTimesheet.holidays.flatMap(
                    (holiday: any) =>
                        holiday.days.map((day: string) => dayjs(day).format("Do MMM YYYY")), // Normalize to "Do MMM YYYY" format
                ),
            );

            updatedActivities[0].days = updatedActivities[0].days.map((day, dayIndex) => {
                // Skip days that have a holiday
                if (holidayDays.has(columns[dayIndex])) {
                    return day; // Do not modify hours for holidays
                }

                // Calculate total hours already assigned for this day, including the current main activity
                const totalAssignedHours = updatedActivities.reduce(
                    (sum, activity) => sum + (activity.days[dayIndex].numberOfHours || 0),
                    0,
                );

                // Calculate remaining hours for the day
                const remainingHours = Math.max(
                    0,
                    (profile?.workingHours ?? 0) - totalAssignedHours,
                );

                // Add only the remaining hours to the main activity
                return {
                    ...day,
                    numberOfHours: Math.min(
                        day.numberOfHours + remainingHours,
                        (profile?.workingHours ?? 0) - totalAssignedHours + day.numberOfHours,
                    ),
                };
            });
        }

        // Return the updated timesheet, ensuring it has the full ITimesheet structure
        return {
            activities: updatedActivities, // Updated activities
            holidays: prevTimesheet.holidays, // Keep holidays unchanged
        };
    });
};

export const calculateTotalSum = (columnsTotals: number[]) => {
    return columnsTotals.reduce((sum, value) => sum + value, 0);
};

export const fillWeek = (
    profile: ProfileModel | null,
    timesheet: ITimesheet,
    columns: any[],
    setTimesheet: (e: any) => void,
    setIsWeekFilled: (e: any) => void,
) => {
    if (!profile?.workingHours) return;

    const startDate = dayjs(profile.startDate);
    const leaveDate = dayjs(profile.leaveDate);
    const today = dayjs();
    const startOfWeek = today.startOf("week").add(1, "day"); // Monday
    const endOfWeek = startOfWeek.add(4, "day"); // Friday

    // Check if profile.startDate or profile.leaveDate is within the current week
    const isRelevantWeek =
        startDate.isBetween(startOfWeek, endOfWeek, "day", "[]") ||
        leaveDate.isBetween(startOfWeek, endOfWeek, "day", "[]");

    if (!isRelevantWeek) {
        setIsWeekFilled(false);
        return;
    }

    // Clone current activities to avoid direct mutation
    const updatedActivities = [...timesheet.activities];

    // Check if "main" activity exists
    let mainActivity = updatedActivities.find((activity) => activity.type === "main");

    if (!mainActivity) {
        // Add a new "main" activity if it doesn't exist
        mainActivity = {
            type: "main",
            selectedActivityId: profile.activityTypeId,
            days: Array(columns.length).fill({ numberOfHours: 0, isActivityApprovedForDay: false }), // Initialize all days with 0 hours
        };
        updatedActivities.push(mainActivity);
    }

    // Create a set of holiday days for quick lookup
    const holidayDays = new Set(
        timesheet.holidays.flatMap((holiday) =>
            holiday.days.map((day) => dayjs(day).format("Do MMM YYYY")),
        ),
    );

    // Fill the week only within the valid date range
    mainActivity.days = mainActivity.days.map((day, dayIndex) => {
        const dayDate = dayjs(columns[dayIndex], "Do MMM YYYY");

        // Skip if the day is before startDate or after leaveDate
        if (dayDate.isBefore(startDate) || dayDate.isAfter(leaveDate)) {
            return { ...day, numberOfHours: 0 }; // Ensure it's not populated
        }

        // Skip days that have a holiday
        if (holidayDays.has(columns[dayIndex])) {
            return day; // Do not modify hours for holidays
        }

        // Calculate total hours already assigned for this day, including the current main activity
        const totalAssignedHours = updatedActivities.reduce(
            (sum, activity) => sum + (activity.days[dayIndex]?.numberOfHours || 0),
            0,
        );

        // Calculate remaining hours for the day
        const remainingHours = Math.max(0, profile.workingHours - totalAssignedHours);

        // Add only the remaining hours to the main activity
        return {
            ...day,
            numberOfHours: Math.min(
                day.numberOfHours + remainingHours,
                profile.workingHours - totalAssignedHours + day.numberOfHours,
            ),
        };
    });

    // Update totals and set the new state
    setTimesheet({ ...timesheet, activities: updatedActivities });
    setIsWeekFilled(true);
};

export const addActivity = (setTimesheet: (e: any) => void, timesheet: ITimesheet) => {
    const newRow = {
        selectedActivityId: undefined,
        type: "other",
        days: Array.from({ length: 5 }, () => ({
            numberOfHours: 0,
            isActivityApprovedForDay: false,
        })),
    };

    setTimesheet((prevTimesheet: ITimesheet) => ({
        ...prevTimesheet, // ✅ Keep other properties unchanged
        activities: [...prevTimesheet.activities, newRow],
    }));
};

export const areButtonsDisabled = (
    timesheet: ITimesheet,
    profile: ProfileModel | null,
    columns: any[],
    startDate: Date, // First day of the selected week
    endDate: Date, // Last day of the selected week
    isWeekFilled: boolean,
) => {
    let buttonsStates = {
        fillWeek: false,
        addActivity: true,
        save: false,
    };

    if (!profile?.startDate) return buttonsStates; // No restrictions if no start date

    const weekStart = dayjs(startDate);
    const weekEnd = dayjs(endDate);

    const filteredColumns = getFilteredColumns(columns, profile, weekStart, weekEnd);

    const hasHolidays = timesheet.holidays.length > 0;

    const isAllDaysHoliday =
        hasHolidays &&
        filteredColumns.every((column) =>
            timesheet.holidays.some((holiday) =>
                holiday.days.some(
                    (day) =>
                        dayjs(day).format("Do MMM YYYY") === column && profile.workingHours === 8,
                ),
            ),
        );

    // const isMainActivityFilled = timesheet.activities.some(
    //     (activity) =>
    //         activity.type === "main" &&
    //         activity.days.every(
    //             (entry, index) =>
    //                 filteredColumns.includes(columns[index]) && // Only check within valid range
    //                 entry.numberOfHours === profile.workingHours,
    //         ),
    // );
    const isMainActivityFilledVar = isMainActivityFilled(
        timesheet,
        filteredColumns,
        columns,
        profile,
    );

    if (isAllWeekApproved(timesheet)) {
        return { fillWeek: true, addActivity: true, save: true };
    }

    if (
        isPastMonth(startDate, endDate) ||
        isFutureMonth(startDate, endDate) ||
        isAfterLast3rdWorkingDay()
    ) {
        return { fillWeek: true, addActivity: true, save: true };
    }

    if (isAllDaysHoliday) {
        return { fillWeek: true, addActivity: true, save: true };
    }

    if (isMainActivityFilledVar && isCurrentMonth(dayjs(startDate).format("Do MMM YYYY"))) {
        return { fillWeek: true, addActivity: false, save: false };
    }
    //
    const columnTotals = profile ? calculateColumnTotals(timesheet, filteredColumns, profile) : [];
    if (calculateTotalSum(columnTotals) === filteredColumns.length * (profile?.workingHours ?? 8))
        return { fillWeek: true, addActivity: false, save: false };
    return buttonsStates;
};
export const isAllWeekApproved = (timesheet: ITimesheet): boolean => {
    const days = timesheet.activities[0]?.days.reduce((acc, day, index) => {
        const isApproved = timesheet.activities.every(
            (activity) => activity.days[index]?.isActivityApprovedForDay,
        );
        return acc + (isApproved ? 1 : 0);
    }, 0);
    return (
        timesheet.activities.length > 0 &&
        days === 5 &&
        timesheet.activities.some((activity) =>
            activity.days
                .filter((day) => day.numberOfHours > 0)
                .some((day) => day.isActivityApprovedForDay),
        )
    );
};

export const isBeforeStartDate = (startDate: string | undefined, date: string): boolean => {
    if (!startDate) return false;

    const parsedStartDate = dayjs(startDate); // ISO date parsing should work correctly
    const parsedDate = dayjs(date, "Do MMM YYYY"); // Ensure correct parsing of "17th Mar 2025"

    return parsedDate.isBefore(parsedStartDate, "day"); // Ensure day-level comparison
};
export const isAfterEndDate = (endDate: string | undefined, date: string): boolean => {
    if (!endDate) return false;

    const parsedEndDate = dayjs(endDate); // ISO format should work correctly
    const parsedDate = dayjs(date, "Do MMM YYYY"); // Ensure correct parsing of "17th Mar 2025"

    return parsedDate.isAfter(parsedEndDate, "day"); // Ensure day-level comparison
};
export const isInputDisabled = (
    row: Activity,
    holidays: Set<string>,
    column: string,
    approvedDay: boolean,
    isAllWeekApproved: boolean,
    startDate?: string,
    leaveDate?: string,
) => {
    const a =
        row.type === "holiday" ||
        row.type === "main" ||
        holidays.has(column) ||
        !isCurrentMonth(column) ||
        isAfterLast3rdWorkingDay() ||
        approvedDay ||
        !row.selectedActivityId ||
        isAllWeekApproved ||
        isBeforeStartDate(startDate, column) ||
        isAfterEndDate(leaveDate, column);
    // return true;

    return a;
};

export const isMainActivityFilled = (
    timesheet: ITimesheet,
    filteredColumns: any[],
    columns: any[],
    profile: ProfileModel | null,
    // currentMonth?: boolean,
) => {
    const bool = timesheet.activities.some(
        (activity) =>
            activity.type === "main" &&
            activity.days.some(
                (entry, index) =>
                    filteredColumns.includes(columns[index]) && // Only check within valid range
                    entry.numberOfHours <= (profile?.workingHours ?? 8) &&
                    isCurrentMonth(
                        columns[index], // Check if the column is in the current month if currentMonth is true
                    ),
            ),
    );
    return bool;
};
export const getFilteredColumns = (
    columns: string[],
    profile: ProfileModel | null,
    weekStart: dayjs.Dayjs,
    weekEnd: dayjs.Dayjs,
) =>
    columns.filter((column) => {
        const columnDate = dayjs(column, "Do MMM YYYY");
        return (
            columnDate.isSameOrAfter(profile?.startDate, "day") && // Must be after startDate
            (!profile?.leaveDate || columnDate.isSameOrBefore(profile.leaveDate, "day")) && // If leaveDate exists, it must be before it
            columnDate.isSameOrAfter(weekStart, "day") && // Must be within the selected week
            columnDate.isSameOrBefore(weekEnd, "day") &&
            isCurrentMonth(column) // Must be in the current month
        );
    });

export const shouldDisableDropdown = (
    row: Activity,
    startDate: Date,
    endDate: Date,
    columns: string[],
    isAllWeekApproved: boolean,
) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const columnBool = columns.some((column, index) => {
        const columnDate = dayjs(column, "Do MMM YYYY");
        return (
            columnDate.isBetween(start, end, "day", "[]") &&
            row.days[index]?.isActivityApprovedForDay
        );
    });
    const bool = row.type === "holiday" || row.type === "main" || columnBool || isAllWeekApproved;
    return bool;
};
