import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { baseURL } from "../../constants";
import { TimesheetContext } from "../../contexts/TimesheetContext";

export const fetchPercentage = async (token: string, month: number) => {
  const response = await fetch(`${baseURL}/api/RD/GetRDPercentages?month=${month}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Couldn't fetch percentage data");
  }
  return response.json();
};

export const useGetPercentage = (month: number, enabled: boolean) => {
  const { accessToken } = useContext(TimesheetContext);

  return useQuery({
    queryKey: ["getPercentage", month, enabled],
    // The query key is an array that includes the month, startDate, and endDate
    queryFn: () => fetchPercentage(accessToken, month),
    enabled: !!accessToken && !!enabled, // Only run the query if accessToken is available
  });
};
