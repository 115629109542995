import { H5, Pill, ProgressBar, ProgressDonut, Span } from "@tag/tag-components-react-v4";
import { UseQueryResult } from "@tanstack/react-query";
import dayjs from "dayjs";

export type RDPercentageProps = {
   data : UseQueryResult<any, Error>
}
export const RDPercentage = (props: RDPercentageProps) => {
    const { data } = props;
    const {isLoading, error, data: percentageData} = data;
    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px", height:"32px" }}>
            {/* <Span>Percentage for <strong>{dayjs().format("MMMM")}:</strong></Span> */}
            {isLoading ? (
                <Span>Loading...</Span>
            ) : data.error ? (
                <Span>Error: {error?.message}</Span>
            ) : percentageData ? (
                <div style={{ display: "flex", gap: "20px", alignItems: "center", height:"32px" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Span>Total Hours:</Span>
                        <H5 style={{marginTop: "-4px"}}>{Math.round(percentageData.totalHours)}</H5>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Span>RD Percentage:</Span>
                        <ProgressBar  value={Math.round(percentageData.rdPercentage)}  type="info" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Span>Non-RD Percentage:</Span>
                        <ProgressBar  value={Math.round(percentageData.nonRdPercentage)}  type="warning" />
                    </div>
                </div>
            ) : <></>}
        </div>
    );
};