import { FormikProps } from "formik";
import {
    Autocomplete,
    DatePicker,
    FormField,
    FormLayout,
    Input,
    P,
    TimePicker,
} from "@tag/tag-components-react-v4";
import EmployeeModalStyles from "./EmployeeModal.module.scss";
import { useGetDepartments } from "../../api/useGetDepartments";
import {
    EmployeeFormFields,
    getMinMaxHours,
    isWeekend,
    offices,
} from "../../utils/employeesPageUtils";
import { useGetDivisions, useGetSubdivisions } from "../../api/useGetDivisions";
import { useGetActivities } from "../../api/useGetActivites";
import { useGetEmployeeTypes } from "../../api/EmployeesApi/useGetEmployeeTypes";
import { EmployeeTypeModel } from "../../models/EmployeeTypeModel";
import { isRDEmployee } from "../../utils/checkUtils";
import { ActivityModel } from "../../models/ActivityModel";
import { useEffect } from "react";

export const EmployeeForm = ({ formik }: { formik: FormikProps<EmployeeFormFields> }) => {
    const { data: departmentsData, isLoading } = useGetDepartments();
    const { data: divisionsData, isLoading: isLoadingDivisions } = useGetDivisions();
    const { data: subdivisionsData, isLoading: isLoadingSubdivisions } = useGetSubdivisions(
        formik.values.divisionId ?? 0,
    );
        const { data: activitiesData, isLoading: isActivitiesLoading } = useGetActivities();
        const { data: employeeTypesData, isLoading: isLoadingEmployeeTypes } = useGetEmployeeTypes();
    
    const minMaxHours = getMinMaxHours(departmentsData, formik.values.departmentId);
    useEffect(() => {
        // Set activityTypeId to null if isEligible is false
        if (!isRDEmployee(formik.values.employeeTypeId)) {
            formik.setFieldValue("activityTypeId", null);
        }
    }, [formik.values.employeeTypeId]);
    return (
        <FormLayout height="300px">
            <div className={EmployeeModalStyles.dateContainer}>
                <FormField
                    label="First name"
                    editor={
                        <Input {...formik.getFieldProps("firstName")} style={{ width: "100%" }} />
                    }
                    invalid={!!(formik.errors.firstName && formik.touched.firstName)}
                    validationErrors={formik.errors.firstName}
                />
                <FormField
                    label="Last name"
                    editor={
                        <Input {...formik.getFieldProps("lastName")} style={{ width: "100%" }} />
                    }
                    invalid={!!(formik.errors.lastName && formik.touched.lastName)}
                    validationErrors={formik.errors.lastName}
                />
                <FormField
                    label="Email"
                    editor={<Input {...formik.getFieldProps("email")} style={{ width: "100%" }} />}
                    invalid={!!(formik.errors.email && formik.touched.email)}
                    validationErrors={formik.errors.email}
                />
                <FormField
                    label="Manager email"
                    editor={
                        <Input
                            {...formik.getFieldProps("managerEmail")}
                            style={{ width: "100%" }}
                        />
                    }
                    invalid={!!(formik.errors.managerEmail && formik.touched.managerEmail)}
                    validationErrors={formik.errors.managerEmail}
                />
                <FormField
                    label="Marca"
                    editor={<Input {...formik.getFieldProps("marca")} style={{ width: "100%" }} />}
                    invalid={!!(formik.errors.marca && formik.touched.marca)}
                    validationErrors={formik.errors.marca}
                />
                <FormField
                    label="Department"
                    editor={
                        <Autocomplete
                            {...formik.getFieldProps("departmentId")}
                            textField="name"
                            valueField="id"
                            data={departmentsData ?? []}
                            renderItem={(item) => (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <P style={{ marginLeft: "8px" }}>{item.name}</P>
                                </div>
                            )}
                            noResultsElement={
                                <P style={{ padding: "8px" }}>No departments found.</P>
                            }
                            isLoading={isLoading}
                            style={{ width: "100%" }}
                            clearButton
                        />
                    }
                    invalid={!!(formik.errors.departmentId && formik.touched.departmentId)}
                    validationErrors={formik.errors.departmentId}
                />
                <FormField
                    label="Division"
                    editor={
                        <Autocomplete
                            {...formik.getFieldProps("divisionId")}
                            textField="name"
                            valueField="id"
                            data={
                                divisionsData
                                    ? divisionsData.map((d) => ({
                                          id: d.id,
                                          name: `${d.shortName} - ${d.name}`,
                                      }))
                                    : []
                            }
                            isLoading={isLoadingDivisions}
                            noResultsElement={<P style={{ padding: "8px" }}>No Divisions found.</P>}
                            clearButton
                            style={{ width: "100%" }}
                        />
                    }
                    invalid={!!(formik.errors.divisionId && formik.touched.divisionId)}
                    validationErrors={formik.errors.divisionId}
                />
                <FormField
                    label="SubDivision"
                    editor={
                        <Autocomplete
                            {...formik.getFieldProps("subdivisionId")}
                            textField="name"
                            valueField="id"
                            data={
                                subdivisionsData
                                    ? subdivisionsData.map((subdivision) => ({
                                          id: subdivision.id,
                                          name: subdivision.name,
                                      }))
                                    : []
                            }
                            disabled={!formik.values.divisionId}
                            noResultsElement={
                                <P style={{ padding: "8px" }}>
                                    No subdivisions found for the selected division.
                                </P>
                            }
                            isLoading={isLoadingSubdivisions}
                            clearButton
                            style={{ width: "100%" }}
                        />
                    }
                    // invalid={!!(formik.errors.subdivisionId && formik.touched.subdivisionId)}
                    // validationErrors={formik.errors.subdivisionId}
                />

                <FormField
                    label="Start Date"
                    editor={
                        <DatePicker
                            {...formik.getFieldProps("startDate")}
                            style={{ width: "100%" }}
                            clearButton
                            onChange={(e) => {
                                formik.setFieldValue("startDate", e.utcDate);
                            }}
                            getDayProps={(date) => {
                                return { disabled: isWeekend(date) };
                            }}
                        />
                    }
                    invalid={!!(formik.errors.startDate && formik.touched.startDate)}
                    validationErrors={formik.errors.startDate}
                />
                <FormField
                    label="Working Hours"
                    editor={
                        <Input {...formik.getFieldProps("workHours")} style={{ width: "100%" }} />
                    }
                    invalid={!!(formik.errors.workHours && formik.touched.workHours)}
                    validationErrors={formik.errors.workHours}
                />
                <FormField
                    label="Schedule Begin"
                    editor={
                        <TimePicker
                            {...formik.getFieldProps("scheduleBegin")}
                            style={{ width: "100%" }}
                            is24HourFormat
                            showSeconds={false}
                            hideNowButton
                            hideConfirmButton={false}
                            minHour={minMaxHours?.[0] ?? 0}
                            maxHour={minMaxHours?.[1] ?? 23}
                            disabled={!formik.values.departmentId}
                        />
                    }
                    invalid={!!(formik.errors.scheduleBegin && formik.touched.scheduleBegin)}
                    validationErrors={formik.errors.scheduleBegin}
                />
                <FormField
                    label="Schedule End"
                    editor={
                        <TimePicker
                            {...formik.getFieldProps("scheduleEnd")}
                            style={{ width: "100%" }}
                            is24HourFormat
                            showSeconds={false}
                            hideNowButton
                            hideConfirmButton={false}
                            minHour={minMaxHours?.[0] ?? 0}
                            maxHour={minMaxHours?.[1] ?? 23}
                            disabled={!formik.values.departmentId}
                        />
                    }
                    invalid={!!(formik.errors.scheduleEnd && formik.touched.scheduleEnd)}
                    validationErrors={formik.errors.scheduleEnd}
                />
                <FormField
                    label="Work Practice"
                    editor={
                        <Input
                            {...formik.getFieldProps("workPractice")}
                            style={{ width: "100%" }}
                        />
                    }
                    invalid={!!(formik.errors.workPractice && formik.touched.workPractice)}
                    validationErrors={formik.errors.workPractice}
                />
                <FormField
                    label="Office Location"
                    editor={
                        <Autocomplete
                            {...formik.getFieldProps("officeLocation")}
                            textField="name"
                            valueField="name"
                            data={offices}
                            renderItem={(item) => (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <P style={{ marginLeft: "8px" }}>{item.name}</P>
                                </div>
                            )}
                            noResultsElement={
                                <P style={{ padding: "8px" }}>No departments found.</P>
                            }
                            style={{ width: "100%" }}
                            clearButton
                        />
                    }
                    invalid={!!(formik.errors.officeLocation && formik.touched.officeLocation)}
                    validationErrors={formik.errors.officeLocation}
                />
                <FormField
                                label="Employee Type"
                                editor={
                                    <Autocomplete
                                        {...formik.getFieldProps("employeeTypeId")}
                                        textField={"type"}
                                        valueField={"id"}
                                        loadingElement={isActivitiesLoading}
                                        clearButton
                                        style={{ width: "100%" }}
                                        data={
                                            employeeTypesData
                                                ? employeeTypesData.map((t: EmployeeTypeModel) => ({
                                                      id: t.id,
                                                      type: t.type,
                                                  }))
                                                : []
                                        }
                                    />
                                }
                                invalid={!!(formik.errors.employeeTypeId && formik.touched.employeeTypeId)}
                                validationErrors={formik.errors.employeeTypeId}
                                
                            />
                            <FormField
                                label="Activity"
                                required={isRDEmployee(formik.values.employeeTypeId)}
                                editor={
                                    <Autocomplete
                                        {...formik.getFieldProps("activityTypeId")}
                                        style={{ width: "100%" }}
                                        textField={"name"}
                                        valueField={"id"}
                                        required={isRDEmployee(formik.values.employeeTypeId)}
                                        data={
                                            activitiesData
                                                ? activitiesData.map((activity: ActivityModel) => ({
                                                      id: activity.id,
                                                      name: activity.descriptionEN,
                                                  }))
                                                : []
                                        }
                                        disabled={!isRDEmployee(formik.values.employeeTypeId)}
                                        loadingElement={isActivitiesLoading}
                                        clearButton
                                    />
                                }
                                invalid={!!(formik.errors.activityTypeId && formik.touched.activityTypeId)&& isRDEmployee(formik.values.employeeTypeId)}
                                validationErrors={formik.errors.activityTypeId && !isRDEmployee(formik.values.employeeTypeId) ? "Activity is required for RD employees" : formik.errors.activityTypeId}
                            />
            </div>
        </FormLayout>
    );
};
