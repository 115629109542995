import { H2, List, ListItem, SimpleButton, useToasts } from "@tag/tag-components-react-v4";
import { useCallback, useContext, useState } from "react";
import { TimesheetRow } from "./TimesheetRow";
import { TimesheetContext } from "../../../contexts/TimesheetContext";
import { TimesheetModel } from "../../../models/TimesheetModel";
import {
    DayChanges,
    getDayWorkedHours,
    initializeDayChanges,
    isLessOrMoreThanExpected,
    prepareTimesheet,
} from "../../../utils/TimesheetUtils";
import { CircleTickFilled, SaveDiskFilled } from "@tag/tag-icons";
import { useFillWeek } from "../../../api/TimesheetApi/useFillWeek";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
// import isBefore from "dayjs/plugin/isBefore";
// import isAfter from "dayjs/plugin/isAfter";
import { useAddUpdateWorkType } from "../../../api/TimesheetApi/useAddUpdateWorkType";
import { useAddUpdateTimesheet } from "../../../api/TimesheetApi/useAddUpdateTimesheet";
import {
    ERROR_COULDNT_ADDUPDATE_TIMESHEET_WORKHOURS,
    TOAST_CONTAINER_ID,
} from "../../../constants";
import { useQueryClient } from "@tanstack/react-query";
dayjs.extend(isBetween);
// dayjs.extend(isBefore); 
// dayjs.extend(isAfter);
export type TimesheetWeekProps = {
    week: TimesheetModel[] | undefined;
};

export const TimesheetWeek = (props: TimesheetWeekProps) => {
    const { week } = props;
    const { profile, accessToken } = useContext(TimesheetContext);
    const queryClient = useQueryClient();
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const [dayHours, setDayHours] = useState<number[]>(Array(week?.length ?? 0).fill(0));
    const [dayChanges, setDayChanges] = useState<DayChanges[]>(
        initializeDayChanges(week?.length ?? 0),
    );
    const { mutate: fillWeek } = useFillWeek();
    const { mutateAsync: addUpdateWorkTypeAsync } = useAddUpdateWorkType();
    const { mutateAsync: addUpdateTimesheetAsync } = useAddUpdateTimesheet();

    const currentWorkHours = dayHours.reduce((total, hours) => total + hours, 0);

    const currentMonth = dayjs().month();
    const currentWeekEnd = dayjs().endOf("week");

    // Check conditions based on new requirements
    const allDaysNotInCurrentMonth = week?.every((day) => dayjs(day.date).month() !== currentMonth);
    const allDaysInFuture = week?.every((day) => dayjs(day.date).isAfter(currentWeekEnd));
  

    // Set the `fillWeekDisabled` and `saveWeekDisabled` states based on the conditions
    const fillWeekDisabled = allDaysNotInCurrentMonth || allDaysInFuture;
    const saveWeekDisabled = allDaysNotInCurrentMonth || allDaysInFuture || currentWorkHours === 0;

    const updateWorkHours = useCallback(
        (index: number, hours: number) => {
            setDayHours((prev) => {
                const updated = [...prev];
                updated[index] = hours;
                return updated;
            });
        },
        [setDayHours],
    );
    const updateDayChanges = useCallback(
        (index: number, changes: DayChanges) => {
            setDayChanges((prev) => {
                const updated = [...prev];
                updated[index] = { ...updated[index], ...changes };
                return updated;
            });
        },
        [setDayChanges],
    );

    const onFillWeek = (): void => {
        if (week) {
            const filteredWeek = week.filter((day) => {
                const dayDate = dayjs(day.date);
                const isInCurrentMonth = dayDate.month() === currentMonth;
                const isAfterStartDate = profile?.startDate ? dayDate.isAfter(dayjs(profile.startDate).subtract(1, 'day')) : true;
                const isBeforeLeaveDate = profile?.leaveDate ? dayDate.isBefore(dayjs(profile.leaveDate).add(1, 'day')) : true;
                return isInCurrentMonth && isAfterStartDate && isBeforeLeaveDate;
            });
    
            const todayEntry = filteredWeek.find((day) =>
                dayjs(day.date).isSame(dayjs().startOf("day"), "day"),
            );
    
            fillWeek(
                {
                    week: {
                        begin: dayjs(filteredWeek[0].date).add(2, "hour").toDate(),
                        end: todayEntry
                            ? dayjs(todayEntry.date).add(2, "hour").toDate()
                            : dayjs(filteredWeek[filteredWeek.length - 1].date)
                                  .add(2, "hour")
                                  .toDate(),
                    },
                    token: accessToken,
                },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries();
                        // toast("Week has been filled", { toastType: "success" });
                    },
                },
            );
        }
    };
    const onSaveAll = async (): Promise<void> => {
        const apiCalls: Promise<any>[] = [];
        week?.forEach((day, index) => {
            if (dayjs(day.date).month() !== currentMonth && !dayjs(day.date).isBefore(dayjs(profile?.leaveDate)) && !dayjs(day.date).isAfter(dayjs(profile?.startDate))) return; // Skip days outside the current month

            const dayChange = dayChanges[index];
            if (!dayChange) return;
            if (dayChange.workType && dayChange.dayBegin && dayChange.dayEnd) {
                apiCalls.push(
                    addUpdateWorkTypeAsync({
                        date: day.date,
                        workTypeId: dayChange.workType,
                        token: accessToken,
                    }),
                );
            }
            if (dayChange.dayBegin && dayChange.dayEnd) {
                if (
                    getDayWorkedHours(dayChange.dayBegin, dayChange.dayEnd) !==
                    profile?.workingHours
                ) {
                    toast(
                        ERROR_COULDNT_ADDUPDATE_TIMESHEET_WORKHOURS +
                            ` (${dayjs(day.date).format("MM/DD/YYYY")})`,
                        {
                            toastType: "error",
                            autoClose: false,
                        },
                    );
                    return;
                }
                const timesheet = prepareTimesheet(dayChange.dayBegin, dayChange.dayEnd, day);
                apiCalls.push(
                    addUpdateTimesheetAsync({ timesheet: timesheet, token: accessToken }),
                );
            }
        });

        await Promise.all(apiCalls)
            .catch((error: Error) => {
                toast(error.message, {
                    toastType: "error",
                });
            })
            .finally(() => setDayChanges(initializeDayChanges(week?.length ?? 0)));
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                marginTop: "1%",
            }}
        >
            <List>
                {week?.map((week, index) => (
                    <ListItem
                        key={`timesheet-row-${index}-${week.date}`}
                        style={{ display: "flow-root" }}
                    >
                        <TimesheetRow
                            weekData={week}
                            key={`timesheet-row-${index}-${week.date}`}
                            updateWorkHours={(hours) => updateWorkHours(index, hours)}
                            updateDayChanges={(changes: DayChanges) =>
                                updateDayChanges(index, changes)
                            }
                        />
                    </ListItem>
                ))}
            </List>
            <div
                style={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "center",
                    color: `${
                        isLessOrMoreThanExpected(currentWorkHours, (profile?.workingHours ?? 0) * 5)
                            ? "#e5173f"
                            : "green"
                    }`,
                }}
            >
                <H2 style={{ color: "inherit" }}>Hours worked:</H2>

                <H2 style={{ color: "inherit" }}>
                    {currentWorkHours}/{(profile?.workingHours ?? 0) * 5}
                </H2>
            </div>
            {/* fill the week until today with what you give as scheduleBegin and scheduleEnd */}
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <SimpleButton
                    startIcon={<CircleTickFilled />}
                    style={{
                        width: "100px",
                        background: fillWeekDisabled ? "#F9F9FA" : "#12213C",
                        color: fillWeekDisabled ? "#B1B8BF" : "#fff",
                    }}
                    onClick={onFillWeek}
                    disabled={fillWeekDisabled}
                >
                    Fill Week
                </SimpleButton>
                {/* show alert if success with "Week has been filled" */}

                {/* added save button for ssaving the timesheet/week , such that not having more calls to do. */}
                <SimpleButton
                    startIcon={<SaveDiskFilled />}
                    accent="teal"
                    onClick={() => onSaveAll()}
                    disabled={saveWeekDisabled}
                >
                    Save Week
                </SimpleButton>
                {/* on save click send data and if success sshow alert of success */}
            </div>
        </div>
    );
};
