import { useContext, useState } from "react";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { TOAST_CONTAINER_ID } from "../../constants";
import { Card, DropDownFlex, H4, SimpleButton, useToasts } from "@tag/tag-components-react-v4";
import { CircleTickFilled, HourglassEmptyLined } from "@tag/tag-icons";
import dayjs from "dayjs";
import { dataMonths, dataYears } from "../../utils/dateUtils";
import {
    useExportDetailedRDReport,
    useExportSimpleRDReport,
} from "../../api/RD/useExportSimpleRDReport";
type RangeDateType = [number, number];

export const RaportGenerators = () => {
    const { accessToken } = useContext(TimesheetContext);
    const { toast } = useToasts(TOAST_CONTAINER_ID);
    const currentMonth = parseInt((dayjs().month() + 1).toString());
    const currentYear = parseInt(dayjs().year().toString());
    const [simpleRangeDate, setSimpleRangeDate] = useState<RangeDateType>([
        currentMonth,
        currentYear,
    ]);
    const [detailedRangeDate, setDetailedRangeDate] = useState<RangeDateType>([
        currentMonth,
        currentYear,
    ]);
    const { mutate: exportSimpleRDReport, isPending: isSimpleExportLoading } =
        useExportSimpleRDReport();
    const { mutate: exportDetailedRDReport, isPending: isExportLoading } =
        useExportDetailedRDReport();
    const handleGenerateSimpleReport = () => {
        if (!simpleRangeDate[0] || !simpleRangeDate[1] || !accessToken) {
            toast("Please select a valid date range.", { toastType: "warning" });
            return;
        }

        toast("Your download of Simple Report will start in a few seconds...", {
            toastType: "information",
        });

        exportSimpleRDReport(
            { month: simpleRangeDate[0], year: simpleRangeDate[1], token: accessToken },
            {
                onError: (error) => {
                    toast(`Failed to generate report: ${error.message}`, { toastType: "error" });
                },
                onSuccess: () => {
                    toast("Report generated successfully!", { toastType: "success" });
                },
            },
        );
    };
    const handleGenerateDetailedReport = () => {
        if (!detailedRangeDate[0] || !detailedRangeDate[1] || !accessToken) {
            toast("Please select a valid date range.", { toastType: "warning" });
            return;
        }

        toast("Your download of Detailed Report will start in a few seconds...", {
            toastType: "information",
        });

        exportDetailedRDReport(
            { month: detailedRangeDate[0], year: detailedRangeDate[1], token: accessToken },
            {
                onError: (error) => {
                    toast(`Failed to generate report: ${error.message}`, { toastType: "error" });
                },
                onSuccess: () => {
                    toast("Report generated successfully!", { toastType: "success" });
                },
            },
        );
    };
    return (
        <Card
            style={{
                textAlign: "center",
                padding: "1%",
                margin: "1%",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 0px 1px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
            }}
        >
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <H4> Simple Report </H4>
                <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
                    <DropDownFlex
                        defaultValue={currentMonth}
                        textField="label"
                        valueField="value"
                        value={simpleRangeDate[0]}
                        data={dataMonths}
                        onChange={(e) =>
                            setSimpleRangeDate([parseInt(e.target.value), simpleRangeDate[1]])
                        }
                    />
                    <DropDownFlex
                        value={simpleRangeDate[1].toString()}
                        data={dataYears()}
                        onChange={(e) =>
                            setSimpleRangeDate([simpleRangeDate[0], parseInt(e.target.value)])
                        }
                    />
                    <SimpleButton
                        disabled={!simpleRangeDate[0] || !simpleRangeDate[1] || isSimpleExportLoading}
                        accent="teal"
                        type="submit"
                        startIcon={
                            isSimpleExportLoading ? <HourglassEmptyLined /> : <CircleTickFilled />
                        }
                        onClick={handleGenerateSimpleReport}
                    >
                        {isSimpleExportLoading ? "Exporting..." : "Generate Report"}
                    </SimpleButton>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <H4> Detailed Report </H4>
                <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
                    <DropDownFlex
                        defaultValue={currentMonth}
                        textField="label"
                        valueField="value"
                        value={detailedRangeDate[0]}
                        data={dataMonths}
                        onChange={(e) =>
                            setDetailedRangeDate([parseInt(e.target.value), detailedRangeDate[1]])
                        }
                    />
                    <DropDownFlex
                        value={detailedRangeDate[1].toString()}
                        data={dataYears()}
                        onChange={(e) =>
                            setDetailedRangeDate([detailedRangeDate[0], parseInt(e.target.value)])
                        }
                    />
                    <SimpleButton
                        disabled={!detailedRangeDate[0] || !detailedRangeDate[1] || isExportLoading}
                        accent="teal"
                        type="submit"
                        startIcon={isExportLoading ? <HourglassEmptyLined /> : <CircleTickFilled />}
                        onClick={handleGenerateDetailedReport}
                    >
                        {isExportLoading ? "Exporting..." : "Generate Report"}
                    </SimpleButton>
                </div>
            </div>
        </Card>
    );
};
