import React, { useState } from "react";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { P, SimpleButton, Tooltip } from "@tag/tag-components-react-v4";
import { TimesheetNav } from "../components/commons/TimesheetNav/TimesheetNav";
import { useGetPercentage } from "../api/RD/useGetPercentage";
import { RDTable } from "../components/RDTimesheet/RDTable";
import { RDPercentage } from "../components/RDTimesheet/RDPercentage";
import { CircleQuestionMarkFilled } from "@tag/tag-icons";

dayjs.extend(LocalizedFormat);

export const RDTimesheetPage = () => {
    const [startDate, setStartDate] = useState<Date>(
        dayjs().startOf("week").add(1, "day").toDate(),
    );
    const [endDate, setEndDate] = useState<Date>(dayjs(startDate).add(4, "day").toDate()); // Friday
    const [enableFetch, setEnableFetch] = useState<boolean>(false);
    const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false); // Track if Save was clicked

    const percentageData = useGetPercentage(dayjs().month() + 1, enableFetch);

    const handlePrevOrNextWeek = (direction: "prev" | "next") => {
        const offset = direction === "prev" ? -7 : 7;
        setStartDate(dayjs(startDate).add(offset, "day").toDate());
        setEndDate(dayjs(endDate).add(offset, "day").toDate());
    };

    const handleCurrentWeek = () => {
        setStartDate(dayjs().startOf("week").add(1, "day").toDate());
        setEndDate(dayjs().startOf("week").add(5, "day").toDate());
    };

    const handleSaveClick = () => {
        setEnableFetch(false); // Disable fetching when Save is clicked
        setIsSaveClicked(true); // Enable the button when Save is clicked
    };

    const handleGetPercentageClick = () => {
        setEnableFetch(true); // Trigger fetching of updated percentage data
        setIsSaveClicked(false); // Reset Save state after fetching
    };

    return (
        <div style={{ margin: "1%" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "1%",
                    alignItems: "center",
                }}
            >
                <TimesheetNav
                    startDate={startDate}
                    endDate={endDate}
                    handlePrevOrNextWeek={handlePrevOrNextWeek}
                    handleCurrentWeek={handleCurrentWeek}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <RDPercentage data={percentageData} />
                    {percentageData.isSuccess ? (
                        <Tooltip
                            tooltipFor={
                                <SimpleButton
                                    disabled={!isSaveClicked} // Enable only if Save is clicked
                                    accent="teal"
                                    onClick={handleGetPercentageClick} // Trigger fetching of updated percentage data
                                >
                                    {percentageData.isLoading
                                        ? "Fetching..."
                                        : `Get % for ${dayjs().format("MMMM")}`}{" "}
                                </SimpleButton>
                                // <CircleQuestionMarkFilled accent="blue"/>
                            }
                        >
                            <P>
                                <strong>Note:</strong> The percentage is calculated based on the
                                current month: <strong>{dayjs().format("MMMM")}</strong>
                            </P>
                            <P>
                                <em>
                                    Save your timesheet to be able to get the updated percentage.
                                </em>
                            </P>
                        </Tooltip>
                    ) : (
                        <SimpleButton
                        // disabled={!isSaveClicked} // Enable only if Save is clicked
                        accent="teal"
                        onClick={handleGetPercentageClick} // Trigger fetching of updated percentage data
                    >
                        {percentageData.isLoading
                            ? "Fetching..."
                            : `Get % for ${dayjs().format("MMMM")}`}{" "}
                    </SimpleButton>
                    )}
                </div>
            </div>
            <RDTable
                startDate={startDate}
                endDate={endDate}
                setEnableFetch={setEnableFetch}
                onSave={handleSaveClick} // Pass the callback to RDTable
            />
        </div>
    );
};
