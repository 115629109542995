import { useMutation } from "@tanstack/react-query";
import { baseURL } from "../../constants";
import { TimesheetContext } from "../../contexts/TimesheetContext";
import { useContext } from "react";

const exportSimpleRDReport = async (month: number, year: number, token: string) => {
	const url = `${baseURL}/api/RD/GenerateSimpleReport?month=${month}&year=${year}`;
	const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Disposition": "attachment; filename=Simple_Report.xlsx",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to export report");
  }
  const blob = await response.blob();
	const objectURL = URL.createObjectURL(blob);
	const tempLink = document.createElement("a");
	tempLink.href = objectURL;
	tempLink.setAttribute("download", `SimpleReportFor_${month}_${year}.xlsx`);
	tempLink.click();
};

export const useExportSimpleRDReport = () => {
	const {accessToken} = useContext(TimesheetContext);
	return useMutation({
		mutationFn: ({ month, year, token }: { month: number; year: number; token: string }) =>
			exportSimpleRDReport(month, year, accessToken),
	});
};


export const exportDetailedRDReport = async (month: number, year: number, token: string) => {
	const url = `${baseURL}/api/RD/GenerateDetailedReport?month=${month}&year=${year}`;
	const response = await fetch(url, {
	method: "GET",
	headers: {
	  "Content-Type": "application/octet-stream",
	  "Content-Disposition": "attachment; filename=Detailed_Report.xlsx",
	  Authorization: `Bearer ${token}`,
	},
  });
  if (!response.ok) {
	throw new Error("Failed to export report");
  }
  const blob = await response.blob();
	const objectURL = URL.createObjectURL(blob);
	const tempLink = document.createElement("a");
	tempLink.href = objectURL;
	tempLink.setAttribute("download", `DetailedReportFor_${month}_${year}.xlsx`);
	tempLink.click();
}

export const useExportDetailedRDReport = () => {
	const {accessToken} = useContext(TimesheetContext);
	return useMutation({
		mutationFn: ({ month, year, token }: { month: number; year: number; token: string }) =>
			exportDetailedRDReport(month, year, accessToken),
	});
};