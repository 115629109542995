import React, { useContext } from "react";
import { TimesheetPage } from "./TimesheetPage";
import { LeaderPage } from "./LeaderPage";
import { LeavesPage } from "./LeavesPage";
import { ProfilePage } from "./ProfilePage";
import { Navigate, Route, Routes } from "react-router-dom";
import { WorkInProgressCard } from "../components/commons/WorkInProgressCard";
import { LogsPage } from "./LogsPage";
import { EmployeesPage } from "./EmployeesPage";
import { TimesheetIOPage } from "./TimesheetIOPage";
import { StatisticsPage } from "./StatisticsPage";
import { RDTimesheetPage } from "./RDTimesheetPage";
import { ManagerRDPage } from "./ManagerRDPage";
import { RDReportPage } from "./RDReportPage";
import { TimesheetContext } from "../contexts/TimesheetContext";
import { isRDEmployee } from "../utils/checkUtils";

export const Pages = () => {
    const { profile } = useContext(TimesheetContext);
    return (
        <Routes>
            
            <Route
                path="/timesheet"
                element={
                    isRDEmployee(profile?.employeeTypeId) ? <RDTimesheetPage /> : <TimesheetPage />
                }
            />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/leaves" element={<LeavesPage />} />
            <Route path="/leader" element={<LeaderPage />} />
            <Route path="/employees" element={<EmployeesPage />} />
            <Route path="/IOReport" element={<TimesheetIOPage />} />
            <Route path="/managerRD" element={<ManagerRDPage />} />
            <Route path="/RDReport" element={<RDReportPage />} />
            <Route path="/statistics" element={<StatisticsPage />} />
            <Route path="/logs" element={<LogsPage />} />
            <Route path="/" element={<Navigate to="/profile" replace />} />
            <Route path="*" element={<Navigate to="/profile" replace />} />
            <Route path="/workInProgress" element={<WorkInProgressCard />} />
            <Route path="/auth/logout" action={() => { sessionStorage.clear(); return true; }}  element={<Navigate to="/profile" replace />} /> 
        </Routes>
    );
};
