import { PillItem } from "@tag/tag-components-react-v4";
import dayjs from "dayjs";
import { REQUIRED_VALIDATION_MESSAGE } from "../constants";
import { DepartmentModel } from "../models/DepartmentModel";
import { EmployeeModel } from "../models/EmployeeModel";
import { ProfileModel } from "../models/ProfileModel";
import { WorkLocationScheduleModel } from "../models/WorkLocationScheduleModel";
import { WorkingHoursModel } from "../models/WorkingHoursModel";
import { isRDEmployee } from "./checkUtils";

export interface EmployeeFormFields {
    firstName: string;
    lastName: string;
    email: string;
    managerEmail: string;
    marca: string;
    departmentId: string;
    workPractice: string;
    officeLocation: string;
    startDate: Date | null;
    workHours: number;
    scheduleBegin: string;
    scheduleEnd: string;
    workLocationSchedule: WorkLocationScheduleModel[];
    leaveDate?: Date;
    activityTypeId?: number;
    divisionId?: number;
    subdivisionId?: number;
    workingHoursHistory?: WorkingHoursModel[];
    employeeTypeId?: number | null;
}

export const offices = [
    {
        name: "Arad",
    },
    {
        name: "Timisoara",
    },
    {
        name: "Remote",
    },
];

export const getEmployeePills = (employee: ProfileModel): PillItem[] | undefined => {
    const employeePills: PillItem[] = [];
    if (employee.department.name)
        employeePills.push({ name: "department", text: employee.department.name, accent: "grey" });
    if (employee.startDate)
        employeePills.push({
            name: "startDate",
            text: dayjs(employee.startDate).format('DD/MM/YYYY'),
            accent: "nileblue",
        });

     if(employee.leaveDate) 
        employeePills.push({
            name:'leaveDate', 
            text: dayjs(employee.leaveDate).format("DD/MM/YYYY"), 
            accent:"brickred",
        })

    return employeePills.length ? employeePills : undefined;
};

export const setFormInitialValues = (employee: ProfileModel | null): EmployeeFormFields => {
    return employee
        ? {
              firstName: employee.firstName,
              lastName: employee.lastName,
              email: employee.email,
              managerEmail: employee.managerEmail,
              marca: employee.marca,
              departmentId: employee.department.id,
              workPractice: employee.workPractice,
              officeLocation: employee.officeLocation,
              startDate: dayjs(employee.startDate).toDate(),
              workHours: employee.workingHours,
              scheduleBegin: employee.scheduleBegin,
              scheduleEnd: employee.scheduleEnd,
              workLocationSchedule: employee.workLocationSchedule,
              divisionId: employee.divisionId,
              subdivisionId: employee.subdivisionId,
              activityTypeId: employee.activityTypeId,
              workingHoursHistory: employee.workingHoursHistory,
              employeeTypeId: employee.employeeTypeId,

          }
        : {
              firstName: "",
              lastName: "",
              email: "",
              managerEmail: "",
              marca: "",
              departmentId: "",
              workPractice: "",
              officeLocation: "",
              startDate: null,
              workHours: 8,
              scheduleBegin: "",
              scheduleEnd: "",
              workLocationSchedule: [],
              divisionId: 0,
              subdivisionId: 0,
              activityTypeId: 0,
              workingHoursHistory: [],
              employeeTypeId: 0,
          };
};

export const validate = (
    values: EmployeeFormFields,
): Partial<Record<keyof EmployeeFormFields, string>> => {
    const errors: Partial<Record<keyof EmployeeFormFields, string>> = {};
    if (!values.firstName) errors.firstName = REQUIRED_VALIDATION_MESSAGE;
    if (!values.lastName) errors.lastName = REQUIRED_VALIDATION_MESSAGE;
    if (!values.email) errors.email = REQUIRED_VALIDATION_MESSAGE;
    if (!values.managerEmail) errors.managerEmail = REQUIRED_VALIDATION_MESSAGE;
    if (!values.marca) errors.marca = REQUIRED_VALIDATION_MESSAGE;
    if (!values.departmentId) errors.departmentId = REQUIRED_VALIDATION_MESSAGE;
    if (!values.workPractice) errors.workPractice = REQUIRED_VALIDATION_MESSAGE;
    if (!values.officeLocation) errors.officeLocation = REQUIRED_VALIDATION_MESSAGE;
    if (!values.startDate) errors.startDate = REQUIRED_VALIDATION_MESSAGE;
    if (!values.workHours) errors.workHours = REQUIRED_VALIDATION_MESSAGE;
    if (!values.scheduleBegin) errors.scheduleBegin = REQUIRED_VALIDATION_MESSAGE;
    if (!values.scheduleEnd) errors.scheduleEnd = REQUIRED_VALIDATION_MESSAGE;
    if ((isRDEmployee(values.employeeTypeId) && !values.activityTypeId))
        errors.activityTypeId = REQUIRED_VALIDATION_MESSAGE;
    if(!values.employeeTypeId) errors.employeeTypeId = REQUIRED_VALIDATION_MESSAGE;
    if(!values.divisionId) errors.divisionId = REQUIRED_VALIDATION_MESSAGE;
    return errors;
};

export const getMinMaxHours = (
    departments: DepartmentModel[] | undefined,
    departmentId: string,
) => {
    const department = departments?.find((department) => department.id === departmentId);
    if (!department) return null;
    return [
        parseInt((department.shiftBegin ?? "00:00").split(":")[0]),
        parseInt((department.shiftEnd ?? "00:00").split(":")[0]),
    ];
};

export const prepareFormValuesForSubmit = (values: EmployeeFormFields): EmployeeModel => {
    return {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        managerEmail: values.managerEmail,
        marca: values.marca,
        departmentId: values.departmentId,
        startDate: values.startDate ? dayjs(values.startDate).add(2, "hours").toISOString() : "",
        workingHours: values.workHours,
        scheduleBegin: values.scheduleBegin,
        scheduleEnd: values.scheduleEnd,
        officeLocation: values.officeLocation,
        leaveDate: values.leaveDate ? dayjs(values.leaveDate).add(2, "hours").toISOString() : null,
        employeeTypeId: values.employeeTypeId ?? null,
        divisionId: values.divisionId ?? null,
        subdivisionId: values.subdivisionId ?? null,
        activityTypeId: values.activityTypeId,
    };
};

export const isWeekend = (date: any) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
};

export const prepareFiredEmployeeValues = (values: ProfileModel): EmployeeModel => {
    return {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        managerEmail: values.managerEmail,
        workingHours: values.workingHours,
        startDate: values.startDate ? dayjs(values.startDate).add(2, "hours").toISOString() : "",
        scheduleBegin: values.scheduleBegin,
        scheduleEnd: values.scheduleEnd,
        marca: values.marca,
        departmentId: values.department.id,
        officeLocation: values.officeLocation,
        leaveDate: values.leaveDate
    }
}